.table{
    color: $light-gray;
    
   .radio,
   .checkbox{
        position: relative;
        height: 20px;
        display: block;
        width: 20px;
        padding: 0px 0px;
        margin: 0px 5px;
        text-align: center;

        .icons{
            left: 5px;
        }
   }
   > thead > tr > th,
   > tbody > tr > th,
   > tfoot > tr > th,
   > thead > tr > td,
   > tbody > tr > td,
   > tfoot > tr > td{
       padding: 1px;
       vertical-align: middle;
       color: $dk-green;
       border-top: 1px solid #242424
   }

    > thead > tr > th{
        border-bottom-width: 1px;
        font-size: $font-size-small;
        text-transform: uppercase;
        color: $light-gray;
        font-weight: $font-weight-normal;
        padding-bottom: 5px;
        border-top: none !important;
        border-bottom: none;
        text-align: left !important;
        position: sticky;
        top: 0;
        z-index: 100; /* Ensures the header stays on top of other content */
        
    }

   .td-actions .btn{
        @include opacity(0.36);

        &.btn-xs{
            padding-left: 3px;
            padding-right: 3px;
        }
    }
    .td-actions{
        min-width: 90px;
    }

    > tbody > tr{
        position: relative;

        &:hover{
            .td-actions .btn{
                @include opacity(1);
            }
        }
    }

    .btn:focus{
        box-shadow: none !important;
    }
}
.table-upgrade{
    .table{
        tr{
            td{
                width: 100%
            }
        }
    }
}

.table .form-check .form-check-sign, .table .form-check .form-check-sign:after, .table .form-check .form-check-sign:before {
    margin-top: -16px;
}

.table-container {
    overflow-y: auto;
    height: 400px; /* Adjust this value based on your requirement */
}

thead th { position: sticky; top: 0; }

.small-header th {
    padding-right: 5px !important;
    padding-left: 5px !important;
    font-size: 8px !important;
    border-right: 1px solid #555 !important;
    letter-spacing: -0.05em;
    color: white !important;
    hr {
        margin: 5px 0px;
        background: #555;
    }
}

.team-spacer {
    text-align: center;
    font-size: 16pt;
    color: white !important;
    background: rgba(0, 0, 0, 0.6);
    letter-spacing: 0.75em;
    text-transform: uppercase;
    font-weight: 900;
    max-width: 100%;
    overflow: hidden;
    text-shadow: 3px 3px 0px black;
}

.highlight {
    background: rgba(255, 255, 255, 1);
    td {
        color: black !important;
        
    }
    .text-white{
        color: black;
        .injury {
            border: 1px solid #dc0a0a;
            background-color: #dc0a0a;
            border-radius: 5px;
            display: inline-block;
            color: white !important;
            padding: 1px;
            margin: 2px;
            width: 100%;
            text-align: center;
        }
    }
}
.highlight :first-child b::before {
    content: "⭐";
}


tr td:first-child {
    padding: 0 !important;
}