.calendar-container {
  display: flex;
  height: 100vh;
  overflow: hidden;
  border: 1px solid #ddd;
  position: relative;
  flex-direction: column;
}

    /* Adjust font size for axis labels */
    .ct-label {
      font-size: 10px; /* Set your desired font size */
    }

    /* Adjust font size for legend items (if applicable) */
    .ct-legend .ct-legend-item {
      font-size: 12px;
    }
  
  .calendar-view {
    width: 75%;
    overflow-y: auto;
    padding-right: 20px;
  }
  
  .calendar-row {
    display: flex;
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
    position: relative;
    margin-left: 40px;
  }
  
  .games-row {
    display: flex;
    overflow-x: auto;
    padding: 10px;
  }
  
  .game-card {
    min-width: 120px;
    background-color: #f2f2f2;
    padding: 6px;
    margin-right: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  

  .teams {
    font-weight: bold;
  }
  
  .score {
    margin-top: 5px;
    color: #555;
  }

  .date-row {
    position: absolute;
    top: 25px;
    transform: rotate(-90deg);
    left: -70px;
    background-color: #666;
    color: white;
    padding: 0px 15px;
    letter-spacing: .1em;
    font-weight: bold;
    margin: 10px;
    font-size: 18pt;
    height: 40px;
    width: 90px;
    text-shadow: 1px 1px 1px #aaa;
  }
  
  /* Sticky Team Filter Bar */
  .team-filter {
    position: sticky;
    top: 0;
    width: 100%;
    background-color: #f9f9f9;
    padding: 10px;
    border-bottom: 2px solid #ddd;
    z-index: 10;
  }
  
  .team-list {
    display: flex;
    justify-content: flex-start;
    gap: 15px;
    white-space: nowrap;
    overflow: scroll;
  }
  
  .team-item {
    cursor: pointer;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: white;
    transition: background-color 0.3s ease;
  }
  
  .team-item.active {
    background-color: #4caf50;
    color: white;
  }
  
  .team-item:hover {
    background-color: #ddd;
  }

  .calendar-name {
    text-align: center;
    font-weight: bold;
    letter-spacing: 0.15em;
    text-shadow: 1px 1px 1px #aaa;
    font-size:12pt;
  }
  
/* Slider on the right side for the dates */
.date-slider {
  position: absolute;
  right: 0;
  top: 70px;
  height: 100%;
  width: 25%;
  background-color: #f9f9f9;
  overflow-y: auto;
  padding: 10px;
  box-shadow: -1px 0px 5px rgba(0, 0, 0, 0.1);
}

.month-section {
  margin-bottom: 10px;
}

.month-item {
  padding: 15px;
  background-color: #fff;
  border-radius: 5px;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.month-item.active {
  background-color: #4caf50;
  color: white;
}

.month-item:hover {
  background-color: #ddd;
}
  
  .date-item {
    padding: 15px;
    margin-bottom: 10px;
    background-color: #fff;
    border-radius: 5px;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .date-item.active {
    background-color: #4caf50;
    color: white;
  }
  
  .date-item:hover {
    background-color: #ddd;
  }
  